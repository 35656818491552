
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    /* background-color: rgb(15 23 42);  */
    background-color: rgba(0, 0, 0, 0.912);
    scroll-behavior: smooth;
}
.advanced-image {
    transition: all 0.6s ease-in-out;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .advanced-image:hover {
    transform: scale(1.1) rotate(1deg); /* Slight zoom and rotation */
    background-color: rgba(255, 255, 255, 0.1); /* Optional: Slight color glow */
  }
  
 