body {
  position: relative;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;}


.swiper {
  width: 100%;
  height: 85vh;
  /* or a fixed height */
}


.swiper-slide {
  text-align: center;
  
  font-size: 18px;
  background: #00000023;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev,.swiper-button-next{
  color: green ;
  
}
.project{
  scroll-snap-align: start;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Simple Loader */
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
